/* eslint-disable */ 
const setting = {

	// 设置Header参数名
	headers: {
		Authorization: 'Authorization',
		contentType: {
			name: 'Content-Type',
			value: 'application/json'
		},
		XAuthorization: 'X-Authorization'
	},
}
module.exports = setting