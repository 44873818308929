/* eslint-disable */ 

import axios from 'axios'
import baseUrl from './baseUrl'
import {headers} from './config'
import { Message } from 'element-ui'
import { setToken, getToken, removeToken, setRefreshToken, getRefreshToken, removeRefreshToken, getIMToken, removeUserInfo } from './storage'

axios.defaults.baseURL = baseUrl.BASE_API;
// 请求超时时间
axios.defaults.timeout = 30000;

// 请求头
axios.defaults.headers.post[headers.contentType.name] = headers.contentType.value;
// 请求拦截器
axios.interceptors.request.use(config => {
	// console.log('请求拦截器=====', config)
	if (config.method == 'post') {
		if (config.isIM) {
			// config.baseURL = 'http://1.94.0.216:9503';
			config.baseURL = baseUrl.IM_API;

			if (getIMToken()) {
				config.headers[headers.Authorization] = "Bearer " + getIMToken();
			}
		} else {
			if (getToken()) {
				config.headers[headers.Authorization] = "Bearer " + getToken();
			}
			if (getRefreshToken()) {
				config.headers[headers.XAuthorization] = "Bearer " + getRefreshToken();
			}
		}
	} else if (config.method == 'get') {
		if (config.params && config.params.isIM == true) {
			// config.baseURL = 'http://1.94.0.216:9503';
			config.baseURL = baseUrl.IM_API;
			var im_token = getIMToken();
			if (im_token) {
				// console.log('getIMToken===' + im_token, config.url)
				config.headers[headers.Authorization] = "Bearer " + im_token;
			}
		} else {
			if (getToken()) {
				config.headers[headers.Authorization] = "Bearer " + getToken();
			}
			if (getRefreshToken()) {
				config.headers[headers.XAuthorization] = "Bearer " + getRefreshToken();
			}
		}
	}

	return config;
}, error => {
	return Promise.reject(error);
});


// 响应拦截器
axios.interceptors.response.use(
	response => {
		if (response.headers['access-token']) {
			setToken(response.headers['access-token'])
		}
		if (response.headers['x-access-token']) {
			setRefreshToken(response.headers['x-access-token'])
		}
		if (response.data && (response.data.code == 400 || response.data.code == 500)) {
			Message.error({
				showClose: true,
				message: response.data.message
			})
			return response
		} else if (response.data && (response.data.code == 401 || response.data.code == 403)) {
			removeToken();
			removeRefreshToken();
			removeUserInfo()
			Message.error({
				showClose: true,
				message: response.data.message
			})
		} else if (response.data && (response.data.code == 429)) {
			return response;
		}
		return response;
	}, error => {
		if (error && error.response) {
			switch (error.response.status) {
				case 401:
					removeToken();
					removeRefreshToken();
					removeUserInfo()
					error.message = error.response.data.msg
					break;
				case 403:
					removeToken();
					removeRefreshToken();
					removeUserInfo()
					error.message = error.response.data.msg
					break;
				case 404:
					error.message = '请求错误,未找到该资源'
					break;
				case 429:
					//处理不提示：访问过于频繁,请稍后重试错误
					return;
				default:
					error.message = `连接错误${error.response.status}`
			}
		}
		Message.error({
			showClose: true,
			message: error.message
		})

		return Promise.reject(error.response.data);
	})

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
			params: params
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, isIM = { isIM: false }) {
	return new Promise((resolve, reject) => {
		axios.post(url, params, isIM)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}

/**
 * 文件上传
 * @param {Object} url
 * @param {Object} params
 */
export function upload(url, params, isIM = { isIM: false }) {
	return new Promise((resolve, reject) => {
		axios.post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, isIM })
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
