/* eslint-disable */
import $ from "jquery"; // 现在采用ES6的语法引入
/**
 * rem 相关的工具
 * @param {*}
 */
export const remUtil = {
  // 调整窗口或者翻转屏幕事件
  resizeEvent: 'orientationchange' in window ? 'orientationchange' : 'resize',
  // 事件句柄
  handlers: [],
  // 添加设置rem的事件
  addRemHandler: function (designWith) {
    function recalc() {
      const docEl = document.documentElement;
      const clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      docEl.style.fontSize = 100 * (clientWidth / Number(designWith)) + 'px';
    };

    if (!document.addEventListener) return;
    const handler = throttle(recalc, 500);
    remUtil.handlers.push(handler);
    window.addEventListener(remUtil.resizeEvent, handler, false);
    document.addEventListener('DOMContentLoaded', recalc, false);
  },
  // 移除设置rem的事件
  removeRemHandler: function () {
    remUtil.handlers.forEach((handler) => {
      window.removeEventListener(remUtil.resizeEvent, handler);
    })
  },
}

/**
 * 删除html上的行内style
 */
export function removeAttributeOfHtml() {
  const docEl = document.documentElement;
  if (!docEl) return;
  docEl.removeAttribute('style');
}

/**
 * viewport的宽度是否比某个值大
 * @param {*} width
 */
export function isWider(width) {
  const viewPortSize = getViewportSize();
  if (viewPortSize.width > width) {
    return true;
  }
  return false;
}

/**
 * 获取viewport的宽高
 */
export function getViewportSize() {
  return {
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  };
}


/**
 * 时间
 */
export function throttle(action, delay) {
  let timeout = null;
  let lastRun = 0;
  return function () {
    if (timeout) return;
    let elapsed = Date.now() - lastRun
    let context = this;
    let args = arguments;
    let runCallback = function () {
      lastRun = Date.now();
      timeout = false;
      action.apply(context, args);
    }
    if (elapsed >= delay) {
      runCallback();
    } else {
      timeout = setTimeout(runCallback, delay);
    }
  }
}

/**
 * 加载静态html代码块
 * @param path
 * @param domId
 */
export function loadHtml(path, domId) {
  $.get(path, function (data) {
    $("#" + domId).html(data)
  })
}

/**
 * 加载静态html代码块
 * @param path
 * @param domId
 */
export function aClass(path, domId) {

  $.get(path, function (data) {
    $("#" + domId).html(data)
  })
}

/**
 * 时间日期转换
 * @param date 当前时间，new Date() 格式
 * @param format 需要转换的时间格式字符串
 * @description format 字符串随意，如 `YYYY-mm、YYYY-mm-dd`
 * @description format 季度："YYYY-mm-dd HH:MM:SS QQQQ"
 * @description format 星期："YYYY-mm-dd HH:MM:SS WWW"
 * @description format 几周："YYYY-mm-dd HH:MM:SS ZZZ"
 * @description format 季度 + 星期 + 几周："YYYY-mm-dd HH:MM:SS WWW QQQQ ZZZ"
 * @returns 返回拼接后的时间字符串
 */
export function formatDate(date, format) {
  let we = date.getDay(); // 星期
  let z = getWeek(date); // 周
  let qut = Math.floor((date.getMonth() + 3) / 3).toString(); // 季度
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月(月份从0开始，要+1)
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    'q+': qut, // 季度
  };
  // 中文数字 (星期)
  const week = {
    '0': '日',
    '1': '一',
    '2': '二',
    '3': '三',
    '4': '四',
    '5': '五',
    '6': '六',
  };
  // 中文数字（季度）
  const quarter = {
    '1': '一',
    '2': '二',
    '3': '三',
    '4': '四',
  };
  if (/(W+)/.test(format))
    format = format.replace(RegExp.$1, RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '星期' + week[we] : '周' + week[we]) : week[we]);
  if (/(Q+)/.test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 4 ? '第' + quarter[qut] + '季度' : quarter[qut]);
  if (/(Z+)/.test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 3 ? '第' + z + '周' : z + '');
  
  for (let k in opt) {
    let r = new RegExp('(' + k + ')').exec(format);
    // 若输入的长度不为1，则前面补零
    if (r) format = format.replace(r[1], RegExp.$1.length == 1 ? opt[k] : opt[k].padStart(RegExp.$1.length, '0'));
  }
  return format;
}

/**
 * 时间日期字符串转换
 * @param dateStr 
 * @param format 需要转换的时间格式字符串
 * @description format 字符串随意，如 `YYYY-mm、YYYY-mm-dd`
 * @description format 季度："YYYY-mm-dd HH:MM:SS QQQQ"
 * @description format 星期："YYYY-mm-dd HH:MM:SS WWW"
 * @description format 几周："YYYY-mm-dd HH:MM:SS ZZZ"
 * @description format 季度 + 星期 + 几周："YYYY-mm-dd HH:MM:SS WWW QQQQ ZZZ"
 * @returns 返回拼接后的时间字符串
 */
export function formatDateStr(dateStr, format) {
  if (dateStr) {
    try {
      let date = new Date(dateStr);
      console.dir(formatDate);
      return formatDate(date, format)
    }
    catch (err) {
      return '';
    }
  }
  else {
    return '';
  }
}

/**
 * 获取当前日期是第几周
 * @param dateTime 当前传入的日期值
 * @returns 返回第几周数字值
 */
export function getWeek(dateTime) {
  let temptTime = new Date(dateTime.getTime());
  // 周几
  let weekday = temptTime.getDay() || 7;
  // 周1+5天=周六
  temptTime.setDate(temptTime.getDate() - weekday + 1 + 5);
  let firstDay = new Date(temptTime.getFullYear(), 0, 1);
  let dayOfWeek = firstDay.getDay();
  let spendDay = 1;
  if (dayOfWeek != 0) spendDay = 7 - dayOfWeek + 1;
  firstDay = new Date(temptTime.getFullYear(), 0, 1 + spendDay);
  let d = Math.ceil((temptTime.valueOf() - firstDay.valueOf()) / 86400000);
  let result = Math.ceil(d / 7);
  return result;
}