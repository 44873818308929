/* eslint-disable */ 
import { get, post, upload } from './request'

//新增游客
export function IM_NewVisitor(data) {
	return post('/api/v1/auth/newvisitor', data, { isIM: true })
}

//游客登录
export function IM_VisitorLogin(data) {
	return post('/api/v1/auth/visitorlogin', data, { isIM: true })
}

//创建与客服的会话
export function IM_TalkCreate(data) {
	return post('/api/v1/talk/create', data, { isIM: true })
}

//获取对话面板中的聊天记录
export function IM_TalkRecordList(data) {
	return get('/api/v1/talk/records', data, { isIM: true })
}
//获取用户相关设置
export function IM_LoginUserInfo(data) {
	return get('/api/v1/users/setting', data, { isIM: true })
}

//发送文本消息
export function IM_TalkMessageTextSend(data) {
	return post('/api/v1/talk/message/text', data, { isIM: true })
}

//更新对话列表未读数
export function IM_TalkUnReadClear(data) {
	return post('/api/v1/talk/unread/clear', data, { isIM: true })
}
//获取会话列表 
export function IM_TalkUserList(data) {
	return get('/api/v1/talk/list', data, { isIM: true })
}

//私聊会话是否存在
export function IM_isExitsPrivateChat(data) {
	return post('/api/v1/talk/isExitsPrivateChatSession', data, { isIM: true })
}
//发送图片消息
export function IM_TalkMessageImageSend(data) {
	return upload('/api/v1/talk/message/image', data, { isIM: true })
}
//IM登录
export function userLoginIm(data) {
	return post('/api/oauth/userLoginIm',data)
}