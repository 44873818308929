import Vue from "vue/dist/vue.esm.js"
import homeUpDownHtml from "../../views/home/components/homeUpDown.html"
import {
  getUpDownList,//涨跌幅
} from "../../tools/util/homeApi.js"
import { setSymbolDefault } from '../../tools/util/storage.js'

export function homeUpDown() {
  Vue.component('updown', {
    template: homeUpDownHtml,
    data: function () {
      return {
        query: {
          page: 1,
          pageSize: 15
        },
        upList: [],
        downList: []
      }
    },
    created: function () {
      this.getData()
    },
    methods: {
      getData() {
        var that = this
        getUpDownList({})
          .then(res => {
            if (res.code == 200) {
              var list = []
              res.result.forEach(item => {
                if (item.children && item.children.length > 0) {
                  list.push(item.children[0])
                }

              })
              list.sort((a, b) => {
                return b.increase - a.increase
              })
              that.upList = []
              that.downList = []
              list.forEach((item, index) => {
                if (index < 5) {
                  that.upList.push(item)
                }
                if (index > list.length - 6) {
                  that.downList.unshift(item)
                }
              })
            }
          })
      },
      toChartView(symbol){
        setSymbolDefault(symbol)
        window.open('/chart.html',"_top")
      }
    }
  });
}