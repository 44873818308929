/* eslint-disable */
import Vue from "vue/dist/vue.esm.js"
import $ from "jquery"; // 现在采用ES6的语法引入
import '../../common/css/common.less';

import LoginPlugin from '../../tools/loginPugin';
Vue.use(LoginPlugin);

import ElementUI from 'element-ui'
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
Vue.use(ElementUI)

import './index.less';
import {
  toolPage,//信号灯
  futureJournalPage,//课程推荐
  treeArticalTypeList,//文章分类
  getArticalPage,//文章列表
  getAdvertList,//banner
} from "../../tools/util/homeApi"
import { headerTab } from "../../tools/public"

import baseUrl from '../../tools/util/baseUrl.js'
Vue.prototype.$baseUrl = baseUrl

import {
  getToken
} from "../../tools/util/storage"

// //banner广告组件
// import { homeBanner } from "../../tools/components/banner.js"
//资讯
import { homeNews } from "../../tools/components/homeNews.js"
//头条报告
import { homeReport } from "../../tools/components/homeReport.js"
//期货开户送礼包
import { homeFuture } from "../../tools/components/homeFuture.js"
//涨跌幅榜
import { homeUpDown } from "../../tools/components/homeUpDown.js"
//多空趋势
import { homeShortLong } from "../../tools/components/homeShortLong.js"
//直播
import { homeLive } from "../../tools/components/homeLive.js"
//在线客服/会员权益/服务 浮动窗口
import { menuFloat } from "../template/menu/menuFloat.js"
//客服弹窗
import { serviceDialog } from "../template/service/serviceDialog.js"
//瓦特币兑换
import { wtCoinExchangeDialog } from "../../tools/components/wtCoinExchangeDialog.js"

var homeApp = new Vue({
  el: '#index',
  data: {
    msg: '123',
    activeIndex: '1',
    data: '',
    bannerList: [],
    toolList: [],
    classList: [],
    tabTypeList: [],
    tabIndex: 0,
    spacialQuery: {
      page: 1,
      pageSize: 12,
      firstTypeId: null
    },
    spacialList: []
  },
  mounted() {
    this.activeIndex = headerTab()

  },
  created() {
    // //banner  组件
    // homeBanner()
    //资讯 组件
    homeNews()
    //头条报告
    homeReport()
    //期货开户送礼包
    homeFuture()
    //涨跌幅榜
    homeUpDown()
    //多空趋势
    homeShortLong()
    //直播
    homeLive()
    //在线客服/会员权益/服务 浮动窗口
    menuFloat()
    //客服弹窗
    serviceDialog()
    //瓦特币兑换
    wtCoinExchangeDialog()

    //信号灯推荐
    this.getToolPage()
    //推荐课程
    this.getFutureJournalPage()
    //文章分类
    this.getArticalTypeList()
    //获取banner数据
    this.getBannerList()
  },
  methods: {
    getBannerList() {
      var that = this;
      getAdvertList({ "groupKey": "Web", "typeCode": 1 })
        .then(res => {
          if (res.code == 200) {
            this.bannerList = res.result
          }
        })
    },
    toBannerItemClick(item) {
      console.log(item, 'toBannerItemClick')
      if (!getToken()) {
        Message.error("请先登录~")
        return
      }
      var type = item.linkTypeCode
      if (type == 1) { //外连接
        if (item.linkUrl) {
          window.open(item.linkUrl)
        }
      } else if (type == 2) { //内链接--文章
        window.open('/articleDetail.html?id=' + item.linkId, "_top")
      } else if (type == 3) { //内链接--战法
        
        
      } else if (type == 4) { //内链接--视频
        window.open('/classDetail.html?id=' + item.linkId, "_top")
      } else if (type == 5) { //内链接--老师

      } else if (type == 6) { //内链接--观点

      } else if (type == 7) { //内链接--期货开户
        window.open(item.linkId)
      } else if (type == 8) { //内链接--开通会员权益
        window.open('/vip.html', "_top")
      } else if (type == 9) { //内链接--新手指南

      }
    },
    toMethodDetail(index) {
      window.open('/method.html?index=' + index, "_top")
    },
    toClassDetail(id) {
      window.open('/classDetail.html?id=' + id, "_top")
    },
    handleSelect(val) {
      this.activeIndex = val;
    },
    getToolPage() {
      toolPage({ "page": 1, "pageSize": 2 })
        .then(res => {
          if (res.code == 200) {
            this.toolList = res.result.items
          }
        })
    },
    getFutureJournalPage() {
      futureJournalPage({
        page: 1,
        pageSize: 2
      }).then(res => {
        if (res.code == 200) {
          this.classList = res.result.items
        }
      })
    },
    getArticalTypeList() {
      this.tabTypeList = [{ name: '资讯', id: null }, { name: '头条报告', id: null }]
      // this.tabTypeList = [{ name: '资讯', id: null }]
      treeArticalTypeList({}).then(res => {
        if (res.code == 200) {
          var list = res.result.filter(x => !x.isShow)
          if (list && list.length > 0) {
            list.forEach(item => {
              this.tabTypeList.push({ name: item.title, id: item.id })
            });
          }
        }
      })
    },
    tabChange(index, item) {
      this.tabIndex = index
      if (this.tabIndex == 2) {
        this.spacialQuery.firstTypeId = item.id
        getArticalPage(this.spacialQuery).then(res => {
          if (res.code == 200) {
            this.spacialList = res.result.items
          }
        })
      }
    },
    toSpacialMore() {
      this.spacialQuery.page += 1
      getArticalPage(this.spacialQuery).then(res => {
        if (res.code == 200) {
          this.spacialList.push(...res.result.items)
        }
      })
    },
    toWTCoinBuyClick(item) {
      if (!getToken()) {
        Message.error("请先登录~")
        return
      }
      if (item.isHaveRight) {
        window.open('/articleDetail.html?id=' + item.id, "_blank")
      } else {
        if (!item.userRightCode) {
          window.open('/articleDetail.html?id=' + item.id, "_blank")
        } else {
          if (item.isCanBuyByWtCoin) {
            // 通知wtCoinExchangeDialog.js弹窗及兑换文章
            const event = new CustomEvent('toUseWtCoinBuy', { detail: item });
            window.dispatchEvent(event);
          } else {
            Message.warning('您没有权限查看详情~');
            return
          }
        }
      }
    },
    toOpenFuture() {
      if (!getToken()) {
        Message.error("请先登录~")
        return
      }
      window.open("https://g.h5gdsvip.com/p/haxu1ga6", '_blank')
  }

  }

})