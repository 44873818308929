import Vue from "vue/dist/vue.esm.js"
import * as echarts from 'echarts';
import homeShortLongHtml from "../../views/home/components/homeShortLong.html"
import {
    getShortLongList,//多空
} from "../../tools/util/homeApi.js"

export function homeShortLong() {
    Vue.component('shortlong', {
        template: homeShortLongHtml,
        data: function () {
            return {
                tradeDate: null,
                height: '300px',
                hasData: true,
                mChart: null,
                symbolList: [],
                buyVolumeList: [],
                sellVolumeList: [],
                buyBeforeList: [],
                sellBeforeList: [],
                imageUrl: require('../../images/shortLong.png'),
            }
        },
        beforeDestory() {
            window.removeEventListener("resize", () => {
                this.mChart.resize();
            });
        },
        created: function () {
            this.getData()
        },
        methods: {
            getData() {
                getShortLongList({}).then(res => {
                    if (res.code == 200) {
                        if (res.result) {
                            this.hasData = true
                            var sortedArray = this.sortByPropertyDescending(res.result, 'volume');
                            sortedArray = sortedArray.slice(0, 10);

                            if (res.result && res.result.length > 0) {
                                this.tradeDate = sortedArray[0].exchangeDate
                            }

                            this.symbolList = sortedArray.map(item => {
                                return item.productName
                            })
                            this.buyVolumeList = sortedArray.map(item => {
                                return item.buyVolume * item.p
                            })
                            this.buyBeforeList = sortedArray.map(item => {
                                return item.buyVolume
                            })
                            this.sellBeforeList = sortedArray.map(item => {
                                return item.sellVolume
                            })
                            this.sellVolumeList = sortedArray.map(item => {
                                return item.sellVolume * item.p
                            })
                            this.$nextTick(() => {
                                this.height = this.symbolList.length * 50;
                                var chartDom = document.getElementById('longShort');
                                this.mChart = echarts.init(chartDom);
                                this.toOptionChart()

                            })
                        } else {
                            this.hasData = false
                        }
                    }
                })
            },
            sortByPropertyDescending(array, property) {
                return array.sort(function (a, b) {
                    return b[property] - a[property];
                });
            },
            toOptionChart() {
                var that = this
                var option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        show: true,
                        textStyle: {
                            color: '#000',
                            fontSize: 12
                        },
                        // left: 'left',
                        data: ['多', '空']
                    },
                    grid: {
                        show: false,
                        top: '6%',
                        left: '0%',
                        right: '0%',
                        bottom: '0%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                show: false   // 取消显示竖线
                            },
                            axisLabel: {
                                color: '#000',
                                rotate: 30,
                                show: false,
                            },
                        }
                    ],
                    yAxis: [
                        {
                            type: 'category',
                            interval: 0,
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                color: '#000',
                                align: 'right',
                            },
                            axisLine: {
                                show: false  // 隐藏y轴轴线
                            },
                            splitLine: {
                                show: false   // 取消显示横线
                            },
                            data: that.symbolList
                        }
                    ],
                    tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                            return '多：' + that.buyBeforeList[params.dataIndex] + '<br/>' + '空：' + that.sellBeforeList[params.dataIndex]
                            + '<br/>' + '多空差：' + (that.buyBeforeList[params.dataIndex] - that.sellBeforeList[params.dataIndex]);
                        }
                    },
                    series: [
                        {
                            name: '多',
                            type: 'bar',
                            stack: 'total',
                            barWidth: 20,
                            // barGap: '10%',          // 系列之间间距设置
                            // barCategoryGap: '10%',  // 系列内柱形之间间距设置
                            label: {
                                show: true,
                                color: '#fff',
                                formatter: function (params) {
                                    return that.buyBeforeList[params.dataIndex];
                                }
                            },
                            itemStyle: {
                                // 普通状态下的样式
                                color: '#bb363b',
                                // // hover状态下的样式    
                                // emphasis: {
                                //     focus: 'none',
                                //     color: '#bb363b',
                                // }
                            },
                            emphasis: {
                                focus: 'none',
                                color: '#bb363b',
                                largeThreshold: 400,
                                itemStyle: {
                                    // 普通状态下的样式
                                    color: '#bb363b',
                                },
                            },
                            data: that.buyVolumeList
                        },
                        {
                            name: '空',
                            type: 'bar',
                            stack: 'total',
                            itemStyle: {
                                // 普通状态下的样式
                                color: '#3dba59',
                                // // hover状态下的样式    
                                // emphasis: {
                                //     color: '#3dba59',
                                //     focus: 'none'
                                // }
                            },
                            barWidth: 20,
                            // barGap: '10%',          // 系列之间间距设置
                            // barCategoryGap: '10%',  // 系列内柱形之间间距设置
                            label: {
                                show: true,
                                color: '#fff',
                                formatter: function (params) {
                                    return that.sellBeforeList[params.dataIndex];
                                }
                            },
                            emphasis: {
                                focus: 'none',
                                color: '#3dba59',
                                largeThreshold: 400,
                                itemStyle: {
                                    color: '#3dba59',
                                },
                            },
                            data: that.sellVolumeList
                        }
                    ]
                };

                option && this.mChart.setOption(option);
                // 点击事件回调函数
                this.mChart.on('click', function (params) {
                    console.log(params.name)
                    // that.$emit('symbol', params.name)
                });
                window.addEventListener("resize", () => {
                    this.mChart.resize();
                });
            },
            getYMD(time) {
                var today = new Date(time);
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();
                return yyyy + "/" + mm + "/" + dd;
            }
        }
    });
}