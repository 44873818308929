import Vue from "vue/dist/vue.esm.js"
import { Message } from 'element-ui'
import homeReportHtml from "../../views/home/components/homeReport.html"
import {
    getArticalPage,//首页资讯
} from "../../tools/util/homeApi.js"
import { getToken } from '../../tools/util/storage.js'

export function homeReport() {
    Vue.component('report', {
        template: homeReportHtml,
        data: function () {
            return {
                query: {
                    page: 1,
                    pageSize: 15
                },
                articleList: []
            }
        },
        created: function () {
            this.getData()
        },
        methods: {
            getData() {
                getArticalPage(this.query)
                    .then(res => {
                        if (res.code == 200) {
                            this.articleList = res.result.items
                        }
                    })
            },
            toMore() {
                this.query.page += 1
                getArticalPage(this.query)
                    .then(res => {
                        if (res.code == 200) {
                            this.articleList.push(...res.result.items)
                        }
                    })
            },
            toWTCoinBuyClick() {
                if (!getToken()) {
                    Message.error("请先登录~")
                    return
                }
            }
        }
    });
}