import Vue from "vue/dist/vue.esm.js"
import { Message } from 'element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import menuFloatHtml from "./menuFloat.html"
import "./menuFloat.less"

import baseUrl from '../../../tools/util/baseUrl.js'
import { webSocketInit } from '../../../tools/util/imSocket.js'

import {
    UUID,
    getUUID,
    getUserInfo,
    setIMToken,
    setIMServiceData,
    getIMToken,
    setIMUserInfo,
    getToken
} from "../../../tools/util/storage.js"
import { getContent } from "../../../tools/util/homeApi.js"
import {
    IM_NewVisitor,
    IM_VisitorLogin,
    IM_LoginUserInfo,
    IM_isExitsPrivateChat,
    IM_TalkCreate,
    IM_TalkUserList,
    userLoginIm
} from "../../../tools/util/imApi.js"

import { IM_OPEN_SERVICE } from "../../../tools/public.js"

export function menuFloat() {
    Vue.component('menufloat', {
        template: menuFloatHtml,
        data: function () {
            return {
                kefuUrl: require('../../../images/kefu.png'),
                equityUrl: require('../../../images/equity.png'),
                serviceUrl: require('../../../images/service.png'),
                wsURL: baseUrl.IM_SOCKET + '?token=',
                serviceCount: 0,
                popupShow:false,
            }
        },
        created: function () {
            console.log('menuFloat created-----------------')
            //创建IM 客户
            if (getToken()) {
                //通过wt接口获取IM登录token
                this.getIMLoginTokenByWT()

            } else {
                UUID()
                this.toNewImVisitor()
            }
            this.getAboutUsContent()
        },
        methods: {
            getAboutUsContent() {
                getContent({ typeCode: 9 }).then(res => {
                    if (res.code == 200) {
                        this.aboutData = res.result
                    }
                })
            },
            toVIPClick() {
                window.open("/vip.html", "_top")
            },
            //通过wt接口获取IM登录token
            getIMLoginTokenByWT() {
                var that = this
                userLoginIm({}).then(res => {
                    if (res.code == 200) {
                        setIMToken(res.result.access_token)
                        that.reconnect()
                        that.getTalkList()
                    }
                })
            },
            //新建游客用户
            toNewImVisitor() {
                IM_NewVisitor({
                    visitorCode: getUUID(),
                    receiver_id: baseUrl.SERVICE_ID
                }).then(res => {
                    if (res.code == 200 || res.code == 2001) {
                        this.toImVisitorLogin()
                    }
                })
            },
            //游客登录
            toImVisitorLogin() {
                var that = this
                IM_VisitorLogin({
                    visitorCode: getUUID()
                }).then(res => {
                    if (res.code == 200) {
                        setIMToken(res.data.access_token)
                        that.getIMUserInfo()
                        //websocket 连接
                        that.reconnect()
                        this.getImChatIsExits()
                    }
                })
            },
            getIMUserInfo() {
                IM_LoginUserInfo({ isIM: true }).then(res => {
                    if (res.code == 200) {
                        setIMUserInfo(JSON.stringify(res.data.user_info))
                    }
                })
            },
            reconnect() {
                console.log('reconnect------------')
                if (getIMToken()) {
                    webSocketInit(this.wsURL + getIMToken())
                    // this.$imSocket.webSocketInit(this.wsURL + getIMToken())
                }
            },
            //私聊会话是否存在
            getImChatIsExits() {
                IM_isExitsPrivateChat({
                    receiver_id: baseUrl.SERVICE_ID
                }).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data.length > 0) {
                            if (!res.data[0]) {
                                this.createTalk()
                            } else {
                                //获取会话列表 在线状态  未读数量等信息
                                this.getTalkList()
                            }
                        }
                    }
                })
            },
            //创建与客服的会话
            createTalk() {
                // talk_type 1:私信，2:群聊
                IM_TalkCreate({
                    receiver_id: baseUrl.SERVICE_ID,
                    talk_type: 1
                }).then(res => {
                    if (res.code == 200) {
                        setIMServiceData(JSON.stringify(res.data))
                    }
                })
            },
            getTalkList() {
                IM_TalkUserList({ isIM: true }).then(res => {
                    if (res.code == 200) {
                        var list = res.data.filter(x => x.receiver_id == baseUrl.SERVICE_ID)
                        if (list && list.length > 0) {
                            // this.isOnline = list[0].is_online == 1
                            setIMServiceData(JSON.stringify(list[0]))
                        }
                    }
                })
            },
            toServiceDialog() {
                this.serviceCount += 1
                IM_OPEN_SERVICE(this.serviceCount)
            },
            togglePopupClick() {  
                this.popupShow = true
            }
        }
    });
}