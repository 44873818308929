import {
    IM_STATUS_NOTIFY,
    IM_EVENT_TALK_NOTIFY,
    IM_EVENT_TALK_KEYBORD_NOTIFY,
    IM_EVENT_LOGIN_NOTIFY
} from "../public.js"

let state = {
    ws: null
}
let keybord = 0;
let reconnectCount = 0;

//初始化ws 用户登录后调用
export function webSocketInit(url) {
    let pingPongInterval = null;
    state.ws = new WebSocket(url);
    window.imWs = state.ws;
    state.ws.onopen = function (res) {
        console.log(" holding data websocket Connection success...");
        IM_STATUS_NOTIFY(1)
    }
    state.ws.onmessage = function (res) {
        // console.log(" holding data websocket receive message...", JSON.stringify(res.data));
        IM_STATUS_NOTIFY(1)
        var data = JSON.parse(res.data)
        if (data.event == 'connect') {
            if (pingPongInterval) {
                clearInterval(pingPongInterval)
                pingPongInterval = null
            }
            pingPongInterval = setInterval(() => {
                var str = {
                    event: 'heartbeat',
                    content: 'ping'
                }
                if (state.ws) {
                    state.ws.send(JSON.stringify(str))
                }
            }, 10000)
        } else if (data.event == 'heartbeat') {
            // heartbeat(pingPongInterval,state)
        } else if (data.event == 'event_talk') {
            IM_EVENT_TALK_NOTIFY(data);
        } else if (data.event == 'event_talk_keyboard') {
            IM_EVENT_TALK_KEYBORD_NOTIFY(keybord++);
        } else if (data.event == 'event_login') {
            IM_EVENT_LOGIN_NOTIFY(res.data);
        }
    }
    state.ws.onclose = function (e) {
        console.log(" holding data websocket Connection failed...");
        IM_STATUS_NOTIFY(0)
        //设置ws状态
        if (window.imWs) {
            state.ws.close()
            state.ws = null
            window.imWs = null
        }
    }
    state.ws.onerror = function (res) {
        console.log(" holding data websocket Connection err..." + res);
        IM_STATUS_NOTIFY(0)
    }
}
//断开重连
export function destoryWebSocket() {
    if (window.imWs) {
        window.imWs.close() // 离开路由之后断开websocket连接
        window.imWs = null
        window.imWs = null
    }
}

