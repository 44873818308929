import Vue from "vue/dist/vue.esm.js"
import { Message } from 'element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
Vue.use(ElementUI)

import './serviceDialog.less';

import serviceDialogHtml from "./serviceDialog.html"

import baseUrl from '../../../tools/util/baseUrl.js'

import {
    getIMToken,
} from "../../../tools/util/storage.js"
import { getContent } from "../../../tools/util/homeApi.js"
import {
    IM_TalkMessageImageSend,
    IM_TalkMessageTextSend,
    IM_TalkRecordList,
    IM_TalkUnReadClear
} from "../../../tools/util/imApi.js"
import {
    textReplaceEmoji,
    textReplaceLink
} from '../../../tools/util/emojis.js'

export function serviceDialog() {
    Vue.component('servicedialog', {
        template: serviceDialogHtml,
        data: function () {
            return {
                logoUrl: require('../../../images/logo-service.png'),
                faceUrl: require('../../../images/face.png'),
                phoneUrl: require('../../../images/icon_tel.png'),
                dialogVisible: false,
                isOnline: false,
                isKeyboard: false,
                aboutData: null,
                sendForm: {
                    receiver_id: baseUrl.SERVICE_ID,
                    talk_type: 1,//1:私信，2:群聊
                    text: ""
                },
                query: {
                    receiver_id: baseUrl.SERVICE_ID,
                    talk_type: 1,//1:私信，2:群聊
                    record_id: 0,//上一次查询的聊天记录ID。
                    isIM: true,//是否IM
                },
                recordList: [],
                isHasData: false,
                receiver_id: baseUrl.SERVICE_ID,
                textContent: null,
            }
        },
        created: function () {
            var that = this
            console.log('serviceDialog created-----------------')
            window.addEventListener('IM_OPEN_SERVICE', function (event) {
                if (event.detail) {
                    that.getAboutUsContent()
                    that.getList()
                    that.dialogVisible = true
                    if (!getIMToken()) return
                    IM_TalkUnReadClear({
                        receiver_id: that.receiver_id,
                        talk_type: 1 //1:私信，2:群聊
                    })
                }
            });
            window.addEventListener('IM_EVENT_TALK', function (event) {
                console.log('event_talk--', event.detail)
                if (event.detail.event == 'event_talk') {
                    var content = event.detail.content
                    if (content.data.msg_type == 1) {
                        content.data.content = textReplaceEmoji(content.data.content)
                        that.recordList.push(content.data)
                        that.scrollBottom()
                    } else if (content.data.msg_type == 2) {
                        content.data.file.path = baseUrl.IM_API + '/' + content.data.file.path
                        that.recordList.push(content.data)
                        that.scrollBottom()
                    }
                }
            });

        },
        methods: {
            getList() {
                if (!getIMToken()) {
                    location.reload()
                }
                IM_TalkRecordList(this.query).then(res => {
                    if (res.code == 200) {
                        var list = res.data.rows.sort((a, b) => {
                            return new Date(a.created_at) - new Date(b.created_at)
                        })
                        list.forEach(item => {
                            if (item.msg_type == 1) {
                                item.content = textReplaceEmoji(item.content)
                            } else if (item.msg_type == 2) {
                                item.file.path = baseUrl.IM_API + '/' + item.file.path
                            }
                        })
                        this.recordList = list
                        this.isHasData = res.data.record_id == 0
                        this.scrollBottom()
                    }
                })
            },
            scrollBottom() {
                setTimeout(() => {
                    this.$nextTick(() => {
                        var div = document.getElementById('myListContainer');
                        if (div) {
                            div.scrollTop = div.scrollHeight;
                            var lastChild = div.lastElementChild;
                            if (lastChild) {
                                div.scrollTop = lastChild.offsetTop + lastChild.clientHeight - div.clientHeight;
                            }
                        }
                    })
                }, 500)
            },
            getAboutUsContent() {
                getContent({ typeCode: 9 }).then(res => {
                    if (res.code == 200) {
                        this.aboutData = res.result
                    }
                })
            },
            toServiceDialog() {
                this.dialogVisible = true
            },
            handleClose() {
                this.dialogVisible = false
            },
            handleCloseDialog() {
                this.dialogVisible = false
            },
            inputText(val) {

            },
            uploadImageChange(e) {
                let file = e.target.files[0]
                if (/\.(gif|jpg|jpeg|png|webp|GIF|JPG|PNG|WEBP)$/.test(file.name)) {
                    this.openImageViewer(e.target.files[0])
                    this.$refs.restFile.value = null
                } else {
                    Message.error({
                        title: '消息',
                        message: '上传图片格式不正确',
                    })
                }
            },
            // 打开图片查看器
            openImageViewer(file) {
                const formData = new FormData();
                formData.append('image', file);
                formData.append('talk_type', 1);
                formData.append('receiver_id', baseUrl.SERVICE_ID);
                IM_TalkMessageImageSend(formData).then(res => {
                    if (res.code == 200) {

                    }
                })
            },
            toSendText() {
                if (!this.textContent.trim()) {
                    Message.error({
                        title: '消息',
                        message: '请输入内容',
                    })
                    return
                }
                this.sendForm.text = this.textContent
                IM_TalkMessageTextSend(this.sendForm).then(res => {
                    if (res.code == 200) {
                        this.textContent = "";
                        this.sendForm.text = ""
                    }
                })
            },
            toGetMore() {
                var that = this
                that.query.record_id = that.recordList[0].id
                IM_TalkRecordList(that.query).then(res => {
                    if (res.code == 200) {
                        var list = res.data.rows.sort((a, b) => {
                            return new Date(a.created_at) - new Date(b.created_at)
                        })
                        list.forEach(item => {
                            if (item.msg_type == 1) {
                                item.content = textReplaceEmoji(item.content)
                            }
                        })
                        that.recordList.unshift(...list)
                        that.isHasData = res.data.record_id == 0
                    }
                })
            },
        }
    });
}