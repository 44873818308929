/* eslint-disable */ 
import { get, post, upload } from './request'

//专栏类型列表
export function classFutureJournalTypeList(data) {
	return post('/api/front/treeFutureJournalTypeList', data)
}




//信号灯列表
export function getWarStrategyList(data) {
	return post('/api/tool/page', data)
}

//战法详情
export function getWarDetails(data) {
	return post('/api/tool/detail', data)
}

//直播
export function getliveBroadcast(data) {
	return post('/api/front/liveBroadcastTypePage', data)
}

//免责申明
export function getRiskDisclaimerData(data) {
	return post('/api/content/getContent', data)
}

//隐私协议
export function getRegAgreement(data) {
	return post('/api/content/getRegAgreement', data)
}
//获取服务条款内容
export function getTermOfService(data) {
	return post('/api/content/getTermOfService', data)
}
//微信登录授权
export function wxOAuth(data) {
	return post('/api/sysUser/oauth',data)
}
//绑定微信并创建账号
export function bindAccount(data) {
	return post('/api/sysUser/bindAccount',data)
}
//微信授权登录
export function autoLogin(data) {
	return post('/api/sysUser/autoLogin',data)
}
//发送绑定微信账户短信验证码
export function sendBindAccountValidateCode(data) {
	return post('/api/sysUser/sendBindAccountValidateCode',data)
}
//微信扫描登录(从缓存中查询有没有扫码登录)
export function scanLogin(data) {
	return post('/api/sysAuth/scanLogin',data)
}
//套利合约列表
export function getGenerateSymbol(data) {
	return post('/api/spread/getGenerateSymbol',data)
}
//获取主力合约tick数据
export function getMainSymbolMarketTick(data) {
	return post('/api/common/getMainSymbolMarket',data)
}
