import Vue from "vue/dist/vue.esm.js"
import homeNewsHtml from "../../views/home/components/homeNews.html"
import {
    GetBroadcast,//首页资讯
} from "../../tools/util/homeApi.js"

export function homeNews() {
    Vue.component('news', {
        template: homeNewsHtml,
        data: function () {
            return {
                query: {
                    page: 1,
                    pageSize: 15
                  },
                  newsList: []
            }
        },
        created: function () {
            this.getData()
        },
        methods: {
            getData() {
                GetBroadcast(this.query)
                  .then(res => {
                    if (res.code == 200) {
                      this.newsList = res.result.items
                    }
                  })
              },
              toMore() {
                this.query.page += 1
                GetBroadcast(this.query)
                  .then(res => {
                    if (res.code == 200) {
                      this.newsList.push(...res.result.items)
                    }
                  })
              }
        }
    });
}