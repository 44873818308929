import Vue from "vue/dist/vue.esm.js"
import { Message } from 'element-ui'
import wtCoinExchangeDialogHtml from "../../views/home/components/wtCoinExchangeDialog.html"
import {
    buyArtical,//瓦特币购买文章
} from "../../tools/util/homeApi.js"
import{refreshUserInfo} from "../../tools/public.js"
import { getUserInfo } from '../../tools/util/storage.js'

export function wtCoinExchangeDialog() {
    Vue.component('wtcoinwxchangedialog', {
        template: wtCoinExchangeDialogHtml,
        data: function () {
            return {
                dialogVisible: false,
                detail: null,
                wtCoin: 0,
            }
        },
        created: function () {
            //    精华文章兑换
            this.initListener();

        },
        methods: {
            initListener() {
                var that = this;
                window.addEventListener('toUseWtCoinBuy', function (event) {
                    console.log(event.detail);
                    that.detail = event.detail;
                    if (getUserInfo()) {
                        that.wtCoin = JSON.parse(getUserInfo()).wtCoin
                    }
                    that.dialogVisible = true;
                });
            },
            toExchangeClick(useCoin, totalCoin) {
                if (useCoin > totalCoin) {
                    Message.error('您的瓦特币不足，请充值~');
                    return
                }
                var that = this;
                buyArtical({
                    articalId: this.detail.id,
                }).then(res => {
                    if (res.code == 200) {
                        Message.success("兑换成功")
                        window.open("/articleDetail.html?id="+that.detail.id,"_blank")
                        refreshUserInfo()
                    }
                })
            },
            handleClose(){
                this.dialogVisible = false
            }
        }
    });
}