import Vue from "vue/dist/vue.esm.js"
import homeLiveHtml from "../../views/home/components/homeLive.html"
import {
    getLiveBroadcastTypePage,//直播
} from "../../tools/util/homeApi.js"

export function homeLive() {
    Vue.component('liveview', {
        template: homeLiveHtml,
        data: function () {
            return {
                imageStyle: {
                    width: '100%', // 初始宽度设置为100%
                    height: '600px' // 初始高度设置为自动  
                },
                list: [],
                item: {},
                index: 0,
            }
        },
        created: function () {
            this.getData()
        },
        methods: {
            toLiveClick() {
                window.open('/live.html', "_top")
            },
            toPrevClick() {
                if (this.index == 0) {
                    this.index = this.list.length - 1
                } else {
                    this.index -= 1
                }
                this.item = this.list[this.index]
            },
            toNextClick() {
                if (this.index == this.list.length - 1) {
                    this.index = 0
                } else {
                    this.index += 1
                }
                this.item = this.list[this.index]
            },
            getData() {
                var that = this
                getLiveBroadcastTypePage({ page: 1, pageSize: 20 }).then(res => {
                    if (res.code == 200) {
                        this.list = res.result.items
                        if (this.list && this.list.length > 0) {
                            this.item = this.list[this.index]
                        } else {
                            this.item = null
                        }
                    }
                })
            },

            formatDate(startTime, endTime) {
                var startDate = new Date(startTime);
                var endDate = new Date(endTime);
                var currentDate = new Date(); // 获取当前时间
                // 计算两个日期之间的差值
                var startDiff = currentDate - startDate;
                var endDiff = currentDate - endDate;
                if (startDiff > 0 && endDiff < 0) {
                    return '直播中';
                } else if (startDiff > 0 && endDiff > 0) {
                    return '未直播';
                } else {
                    const year = startDate.getFullYear();
                    const month = String(startDate.getMonth() + 1).padStart(2, '0');
                    const day = String(startDate.getDate()).padStart(2, '0');
                    const hour = String(startDate.getHours()).padStart(2, '0');
                    const minute = String(startDate.getMinutes()).padStart(2, '0');
                    return `时间：${month}/${day} ${hour}:${minute}`;
                }
            },
        }
    });
}