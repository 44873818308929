/* eslint-disable */ 
//设置固定值
const WT_Token = 'access_token' //用户Token
const RefreshToken = 'refresh_token' //用户Token
const UserInfo = "userInfo" //用户信息
const RequireId = "requireId" //这里可以设置在请求时必须携带的一些参数
const Lock = "lock" //锁
const IM_UUID = 'UUID';//IM 游客UUID
const IM_TOKEN = 'im_token';//IM 游客token
const IM_SERVICE_DATA = 'IMServiceData';//IM 客服头像
const IM_USER_INFO = 'im_user_info';//IM登录用户信息
const INDICATORLIST = "tool_indicator_list";//指标列表
const SYMBOLDEFAULT = "default_symbol";//tradingview切换合约
const INRERVALDEFAULT = "default_interval";//tradingview切换周期
const WX_STATE = "wx_state";//wx 授权的state


//设置accessToken
export function setToken(token) {
    localStorage.setItem(WT_Token, token);

    let exDate = new Date();
    exDate.setDate(exDate.getDate() + 7);
    document.cookie = `access_token=${ token };expires=${exDate.toGMTString()}`;
}

//获取accessToken
export function getToken() {
    return localStorage.getItem(WT_Token);
}

//设置RefreshToken
export function setRefreshToken(token) {
    localStorage.setItem(RefreshToken, token);
}

//获取RefreshToken
export function getRefreshToken() {
    return localStorage.getItem(RefreshToken);
}

//删除accessToken
export function removeToken() {
    localStorage.removeItem(WT_Token);

    let exDate = new Date();
    exDate.setTime(exDate.getTime() - 1);
    document.cookie = `access_token=;expires=${exDate.toGMTString()}`;
}
//删除RefreshToken
export function removeRefreshToken() {
    localStorage.removeItem(RefreshToken);
}
//设置userInfo
export function setUserInfo(userInfo) {
    localStorage.setItem(UserInfo, userInfo);
}

//获取userInfo
export function getUserInfo() {
    return localStorage.getItem(UserInfo);
}

//删除userInfo
export function removeUserInfo() {
    localStorage.removeItem(UserInfo);
}

//设置requireId
export function setRequireId(requireId) {
    localStorage.setItem(RequireId, requireId);
}

//获取requireId
export function getRequireId() {
    return localStorage.getItem(RequireId);
}

//删除requireId
export function removeRequireId() {
    localStorage.removeItem(RequireId);
}

//设置lock
export function setLock(lock) {
    localStorage.setItem(Lock, lock);
}

//获取lock
export function getLock() {
    return localStorage.getItem(Lock);
}

//删除lock
export function removeLock() {
    localStorage.removeItem(Lock);
}

//退出登录删除相关信息
export function removeAll() {
    removeToken();
    removeRefreshToken();
    removeUserInfo();
    removeRequireId();
    removeLock();
    removeIndicatorList();
}

export function UUID() {
    var uuid = localStorage.getItem(IM_UUID);
    if (!uuid) {
        // 获取当前时间戳（毫秒级）  
        let timestamp = Date.now().toString(36);
        // 生成一个随机的10位十六进制数  
        let randomPart = Math.random().toString(36).substr(2, 10);
        // 将时间戳和随机部分拼接起来  
        let newId = timestamp + randomPart;
        localStorage.setItem(IM_UUID, newId);
        uuid = newId;
    }
}
//获取lock
export function getUUID() {
    return localStorage.getItem(IM_UUID);
}

//设置IM accessToken
export function setIMToken(token) {
    localStorage.setItem(IM_TOKEN, token);
}

//获取IM accessToken
export function getIMToken() {
    return localStorage.getItem(IM_TOKEN);
}
//清除IM accessToken
export function removeIMToken() {
    return localStorage.removeItem(IM_TOKEN);
}

//获取IM 客服聊天在线状态和未读数量等信息
export function setIMServiceData(data) {
    return localStorage.setItem(IM_SERVICE_DATA, data);
}
//获取IM 客服聊天在线状态和未读数量等信息
export function getIMServiceData() {
    return localStorage.getItem(IM_SERVICE_DATA);
}
//保持IM登录用户信息
export function setIMUserInfo(data) {
    return localStorage.setItem(IM_USER_INFO, data);
}
//获取IM登录用户信息
export function getIMUserInfo() {
    return localStorage.getItem(IM_USER_INFO);
}

//保存战法领取成功后的指标
export function setIndicatorList(data) {
    return localStorage.setItem(INDICATORLIST, data);
}
//获取战法领取成功后的指标
export function getIndicatorList() {
    return localStorage.getItem(INDICATORLIST);
}
//清除战法领取成功后的指标
export function removeIndicatorList() {
    return localStorage.removeItem(INDICATORLIST);
}
//保存tradingview切换合约
export function setSymbolDefault(data) {
    return localStorage.setItem(SYMBOLDEFAULT, data);
}
//获取tradingview切换合约
export function getSymbolDefault() {
    return localStorage.getItem(SYMBOLDEFAULT);
}

//保存tradingview切换周期
export function setIntervalDefault(data) {
    return localStorage.setItem(INRERVALDEFAULT, data);
}
//获取tradingview切换周期
export function getIntervalDefault() {
    return localStorage.getItem(INRERVALDEFAULT);
}
/**
 * 图片转base64
 * @param {随机字符串}len
 */
export function getRandomString(len) {
    let _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789',
        min = 0,
        max = _charStr.length - 1,
        _str = '';                    //定义随机字符串 变量
    //判断是否指定长度，否则默认长度为15
    len = len || 15;
    //循环生成字符串
    for (var i = 0, index; i < len; i++) {
        index = (function (randomIndexFunc, i) {
            return randomIndexFunc(min, max, i, randomIndexFunc);
        })(function (min, max, i, _self) {
            let indexTemp = Math.floor(Math.random() * (max - min + 1) + min),
                numStart = _charStr.length - 10;
            if (i == 0 && indexTemp >= numStart) {
                indexTemp = _self(min, max, i, _self);
            }
            return indexTemp;
        }, i);
        _str += _charStr[index];
    }
    const currentDate = new Date();
    const milliseconds = currentDate.getTime(); // 返回当前时间的毫秒数
    return _str + milliseconds;
}

//保存wx 授权的state
export function setWXState(data) {
    return localStorage.setItem(WX_STATE, data);
}
//获取wx 授权的state
export function getWXState() {
    return localStorage.getItem(WX_STATE);
}