/* eslint-disable */ 
import { get, post, upload } from './request'

//首页banner
export function getAdvertList(data) {
	return post('/api/advert/list', data)
}
//信号灯推荐
export function toolPage(data) {
	return post('/api/tool/page', data)
}
//课程推荐
export function futureJournalPage(data) {
	return post('/api/front/futureJournalPage', data)
}
//文章类型
export function treeArticalTypeList(data) {
	return post('/api/front/treeArticalTypeList', data)
}
//首页资讯
export function GetBroadcast(data) {
	return get('/api/Broadcast/GetBroadcast', data)
}
//首页头条报告--文章
export function getArticalPage(data) {
	return post('/api/front/articalPage', data)
}
//首页头条报告--文章详情
export function getArticalDetail(data) {
	return get('/api/front/articalInfo', data)
}
//首页头条报告--文章阅读数量
export function getArticalReadCount(data) {
	return get('/api/front/viewArtical', data)
}
//首页期货商期货开户列表
export function getOpenFutureMerchant(data) {
	return post('/api/futuresMerchant/pageApp', data)
}
//首页涨跌幅榜
export function getUpDownList(data) {
	return get('/api/common/GetHotUsageChat', data)
}
//首页多空趋势
export function getShortLongList(data) {
	return get('/api/common/ReportProductTrendBar', data)
}
//首页直播
export function getLiveBroadcastTypePage(data) {
	return post('/api/front/liveBroadcastTypePage', data)
}
// 期货商持仓统计
export function futureTraderSymbolVolume(data) {
	return post('/api/common/futureTraderSymbolVolume', data);
};

// 获取合约成交日期列表
export function getSymbolVolumeDateList(data) {
	return post('/api/common/getSymbolVolumeDateList', data);
};

// 主力行情
export function futureCountMarketList(data) {
	return post('/api/common/futureCountMarketList', data);
};

//主力成交额
export function getMainProductTurnover(data) {
	return get('/api/common/GetMainProductTurnover', data)
}

//免责申明 会员开通协议 
export function getContent(data) {
	return post('/api/content/getContent',data)
}
//会员权益  -- 价格表
export function getMemberPriceList(data) {
	return post('/api/member/memberPriceList',data)
}
//生成微信Native支付地址
export function nativePayUrl(data) {
	return post('/api/weiXinPay/nativePayProductUrl',data)
}
//获取支付结果
export function getPayResult(data) {
	return post('/api/weiXinPay/getPayResult',data)
}
//获取登录用户信息
export  function userInfo(data) {
    return get('/api/sysUser/baseInfo', data)
}
//使用瓦特币购买文章
export function buyArtical(data) {
	return post(`/api/userBrowseArticalRight/buyArtical`,data)
}

//发布老师最新市场解读
export function teacherMarketInterpretAdd(data) {
	return post(`/api/teacherMarketInterpret/add`,data)
}
//老师最新市场解读列表
export function teacherMarketInterpretPage(data) {
	return post(`/api/teacherMarketInterpret/page`,data)
}