import Vue from "vue/dist/vue.esm.js"
import { Message } from "element-ui";
import homeFutureHtml from "../../views/home/components/homeFuture.html"
import {
    getOpenFutureMerchant,//期货商开户列表
} from "../../tools/util/homeApi.js"
import { getToken } from "../../tools/util/storage.js"

export function homeFuture() {
    Vue.component('futureopen', {
        template: homeFutureHtml,
        data: function () {
            return {
                query: {
                    page: 1,
                    pageSize: 15
                },
                futureList: []
            }
        },
        created: function () {
            this.getData()
        },
        methods: {
            getData() {
                getOpenFutureMerchant({})
                    .then(res => {
                        if (res.code == 200) {
                            this.futureList = res.result.items
                        }
                    })
            },
            toOpenFuture(item) {
                if (!getToken()) {
                  Message.error("请先登录~")
                  return
                }
                window.open(item.webUrl, '_blank')
            }
        }
    });
}